<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getPermissionGroups()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-lock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Permission Groups
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getUsersList()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-multiple
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Users
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-cog-outline
                </v-icon>
              </v-list-item-icon>
              Settings
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getPermissionGroups()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-lock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Permission Groups
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getUsersList()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-multiple
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Users
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <permission-groups v-if="showPermissionGroups" />
      <users-list v-if="showUsersList" />
    </div>
  </v-container>
</template>

<script>
import PermissionGroups from './AccountSettings/PermissionGroups.vue';
import UsersList from './users/UsersList.vue';

 export default {
    name: 'Settings',
    components: {
      'permission-groups': PermissionGroups,
      'users-list': UsersList,
    },
    data: () => ({
      selectedItem: -1,
      showPermissionGroups: true,
      showUsersList: false,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      getPermissionGroups () {
        this.showUsersList = false;
        this.showPermissionGroups = true;
      },
      getUsersList () {
        this.showPermissionGroups = false;
        this.showUsersList = true;
      },
    },
  };

</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
</style>
